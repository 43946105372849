<template>
    <div>
        <v-navigation-drawer
            v-model="drawer"
            :clipped="$vuetify.breakpoint.lgAndUp"
            fixed
            app
            style="width: 280px;"
        >

        <template v-slot:prepend>
            <v-toolbar color="#ef6b01">
                <v-card-title style="color:white">Menú</v-card-title>
            </v-toolbar>
        </template>
        <!-- <template v-slot:append>
          <div class="pa-2">
            <v-btn disabled block>
                230824.0
            </v-btn>
          </div>
        </template> -->

        <v-divider></v-divider>

        <v-list dense>

            <template>
            <v-list-item link @click="IrPrincipal" dense style="height: 66px;">
               <v-list-item-avatar :rounded="true" size="70px" horizontal>
                    <template>
                        <v-img src="../assets/inicio.jpg" contain></v-img>
                    </template>
                </v-list-item-avatar>

                <v-list-item-content>
                <v-list-item-title class="letra-app">Inicio</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider style="margin-left: 80px;"></v-divider>
            <v-list-item link @click="irMisPedidos" dense style="height: 66px;">
                <v-list-item-avatar :rounded="true" size="70px" horizontal>
                    <template>
                        <v-img src="../assets/mis_ordenes.jpg" contain></v-img>
                    </template>
                </v-list-item-avatar>

                <v-list-item-content>
                <v-list-item-title class="letra-app">Pedidos</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider style="margin-left: 80px;"></v-divider>
            <v-list-item @click="irCotizaciones" link dense style="height: 66px;">
                <v-list-item-avatar :rounded="true" size="70px" horizontal>
                    <template>
                        <v-img max-width="46px" src="../assets/cotizaciones.jpeg" contain></v-img>
                    </template>
                </v-list-item-avatar>

                <v-list-item-content>
                <v-list-item-title class="letra-app">Cotizaciones</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider style="margin-left: 80px;"></v-divider>
            <v-list-item v-if="usuario.muestraCatalogoPuntos==1" @click="irCatalogo" link dense style="height: 66px;">
                <v-list-item-avatar :rounded="true" size="70px" horizontal>
                    <template>
                        <v-img src="../assets/catalogo.jpg" contain></v-img>
                    </template>
                </v-list-item-avatar>

                <v-list-item-content>
                <v-list-item-title class="letra-app" style="line-height: inherit;">Catálogo de puntos</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider v-if="usuario.muestraCatalogoPuntos==1" style="margin-left: 80px;"></v-divider>
            <v-list-item v-if="usuario.muestraCatalogoPuntos==1 && usuario.muestra_mis_canjes==1" @click="irMisCanjes" link dense style="height: 66px;">
                <v-list-item-avatar :rounded="true" size="70px" horizontal>
                    <template>
                        <v-img src="../assets/perfil.jpg" contain></v-img>
                    </template>
                </v-list-item-avatar>

                <v-list-item-content>
                <v-list-item-title class="letra-app" style="line-height: inherit;">Mis canjes</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider v-if="usuario.muestraCatalogoPuntos==1 && usuario.muestra_mis_canjes==1" style="margin-left: 80px;"></v-divider>
            <v-list-item @click="irMiPerfil" link dense style="height: 66px;">
                <v-list-item-avatar :rounded="true" size="70px" horizontal>
                    <template>
                        <v-img src="../assets/perfil.jpg" contain></v-img>
                    </template>
                </v-list-item-avatar>

                <v-list-item-content>
                <v-list-item-title class="letra-app">Perfil</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            
            <v-divider style="margin-left: 80px;"></v-divider>
            <div class="pa-2">
            <v-btn disabled block style=" background-color: white !important; color: grey !important;;">
                071024.01
            </v-btn>
          </div>
            <!-- <v-list-item link @click="cerrarSesion" dense style="height: 66px;">
                <v-list-item-avatar :rounded="true" size="70px" horizontal>
                    <template>
                        <v-img src="../assets/cerrar_sesion.jpg" contain></v-img>
                    </template>
                </v-list-item-avatar>

                <v-list-item-content>
                <v-list-item-title class="letra-app">Cerrar Sesión</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider style="margin-left: 80px;"></v-divider> -->
            

            </template>
        </v-list>
        
    </v-navigation-drawer>

        <v-app-bar
        class="app-bar"
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        color="#ef6b01"
        dark
        app
        fixed
        >
        <!-- <v-app-bar-nav-icon icon >
          <v-icon>mdi-database</v-icon>
        </v-app-bar-nav-icon>
     -->
        
        <v-row>
            <v-col class="d-flex justify-start">
                <v-app-bar-nav-icon  @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            </v-col>
            <v-col style="align-items: center; display: flex;">
                <v-img
                    class="mx-2"
                    src="../assets/Principal.png"
                    :max-height="heightLogo"
                    :max-width="widthLogo"
                    contain
                ></v-img>
            </v-col>
            <v-col class="d-flex justify-end">
                <v-spacer></v-spacer>
                <v-btn @click="irBuscador" icon style="margin-right: 2vw;"><v-icon>mdi-magnify</v-icon></v-btn>
            </v-col>
        </v-row>
        
        </v-app-bar>
    </div>
</template>

<script>
import ApiServer from '../api';
import {mapState} from 'vuex';
export default {
    data(){
        return{
            drawer: false,
            muestraCatalogo:false
        }
    },
    methods: {
        IrPrincipal(){
            if (this.$route.path !== '/Home') 
            this.$router.push({name: 'Home'});
            this.drawer = !this.drawer
        },

        // cerrarSesion(){
        //     Cookies.remove('token')
        //     localStorage.clear();
        //     window.location.reload();
        // },
        irBuscador(){
            this.$router.push({name: 'Buscador'})
        },
        irMisPedidos(){
            this.$router.push({name: 'MisPedidos'})
        },
        async irCatalogo(){
            await ApiServer.postUsoLog({
                cod_cli: this.usuario.cod_cli,
                usuario: this.usuario.nombreUsuario,
                accion: 'CATALOGO DE PUNTOS',
                dato: ''
            })
            this.$router.push({name: 'Catalogo'})
        },
        irMisCanjes(){
            this.$router.push({name: 'MisCanjes'})
        },
        irMiPerfil(){
            this.$router.push({name: 'MiPerfil'})
        },
        irCotizaciones(){
            this.$router.push({name: 'Cotizaciones'})
        }

    },
    async mounted(){
        // try {
        //     let resp = await ApiServer.getAplicacionUsuario(this.usuario.userId)
        //     if(resp.length>0){
        //         if(resp[0].muestra_catalogo_puntos==1){
        //             this.muestraCatalogo = true
        //         }
        //     }
        // } catch (error) {
        //     console.log(error);
        // }
    },  
    computed:{
        ...mapState(['usuario']),
        widthLogo(){
            if(this.$vuetify.breakpoint.name=='xs'){
                return '160px'
            }else if(this.$vuetify.breakpoint.name=='sm'){
                return '200px'
            }else if(this.$vuetify.breakpoint.name=='md'){
                return '230px'
            }else return '230px'
        },
        heightLogo(){
            if(this.$vuetify.breakpoint.name=='xs'){
                return '40px'
            }else if(this.$vuetify.breakpoint.name=='sm'){
                return '40px'
            }else if(this.$vuetify.breakpoint.name=='md'){
                return '50px'
            }else return '50px'
        }
    },

}
</script>

<style scoped>
    .letra-app{
        font-size: 20px !important;
        font-family: sans-serif;
    }
    .app-bar >>> .v-toolbar__content{
        padding: 0px;
        background-color: #ef6b01;

    }
</style>
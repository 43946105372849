import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import {faPaperPlane } from '@fortawesome/free-regular-svg-icons'
import {faReceipt,faMoneyBills} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faPaperPlane, faReceipt,faMoneyBills)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.filter('toCurrency', function (value) {
  if(typeof value !== "number"){
    return value;
  }
  var formatter = new Intl.NumberFormat('en-US',{
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0
  });
  return formatter.format(value)
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
